.comments-container {
    max-width: 768px;
    margin: 0 auto;
    padding: 15px;
    margin-top: 50px;
}

.comment-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    overflow: hidden;
}

.comment-header {
    display: flex;
    align-items: center;
    padding: 15px;
    background: linear-gradient(to right, #173444, #336581, #487f9d);
}

.user-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 15px;
    border: 2px solid #fff;
}

.user-name {
    font-weight: bold;
    color: #fff;
}

.comment-rating {
    display: flex;
}

.star-filled {
    color: #ffc107;
}

.star-empty {
    color: #e4e5e9;
}

.comment-date {
    font-size: 0.8em;
    color: rgba(255, 255, 255, 0.9);
}

.comment-body {
    padding: 15px;
    font-size: 0.9em;
    color: #333;
    border-top: 1px solid #f0f0f0;
}

.comments-container {
    max-height: 235px; /* Ajusta este valor según el tamaño de tus tarjetas de comentarios */
    overflow-y: auto;
}

.delete-comment-button{
    background-color: #336581;
    border: none;
    font-family: Arial, Helvetica, sans-serif;
    padding: 8px 16px;
    width: 100%;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.delete-comment-button:hover{
    background-color: #d94141;
    border-color: #d94141;
    transform: scale(1.002);
}

