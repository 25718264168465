.comment-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Open Sans', sans-serif;
    /* Fuente moderna */
}

.comment-stars {
    display: flex;
    flex-direction: row;
}

.comment-textarea {
    border: 1px solid #ccc;
    /* Color actualizado */
    border-radius: 8px;
    /* Bordes redondeados */
    padding: 15px;
    margin: 20px 0;
    min-height: 120px;
    width: 100%;
    /* Responsivo */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Sombra suave */
}

.comment-submit-button {
    transition: background-color 0.3s ease, transform 0.3s ease;
    background-color: #336581;
    border: none;
    font-family: Arial, Helvetica, sans-serif;
    padding: 8px 16px;
    width: 100%;
    border-radius: 20px;
}

.comment-submit-button:hover {
    background-color: darkolivegreen;
    border-color: darkolivegreen;
    transform: scale(1.002);
}

.comment-star {
    margin-right: 10px;
    cursor: pointer;
    transition: color 0.3s ease, transform 0.2s ease;
}

.comment-star:hover {
    color: #FFBA5A;
    /* Color de hover */
    transform: scale(1.1);
    /* Efecto de crecimiento */
}

