.profile-image {
    width: 230px;
    height: 230px;
    object-fit: cover;
}

.perfil-descripcion{
    width: 100%;
    height: 100px;
    resize: none;
}

.edit-description{
    width: 100%;
    height: 100px;
    resize: none;
}