/* Estilos para el encabezado */
header {
  box-shadow: 20px 20px 20px #0b1f2c;
  background:  linear-gradient(to bottom, #537692, #224e66, #173444); 
}

/*#16344B*/

/* Definición de la animación de entrada */
@keyframes slideIn {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.logo{
  margin-left: 2px;
}

.titulo-logo{
  text-align: start;
}

.botones1-header{
  text-align: end;
}

.botones2-header{
  text-align: end;
}

.boton-registrarse{
  /* padding: 5px 12px; */
  background-color: #7094b2;
  border-radius: 20px;
  border: none;
}

.boton-registrarse:hover{
  background-color: #ffa500;
  border-color: #ffa500;
  transform: scale(1.05);
}

.boton-login{
  /* padding: 5px 12px; */
  background-color: #7094b2;
  border-radius: 20px;
  font-size: 15px;
  border: none;
}

.boton-login:hover{
    background-color: orange;
    border-color: orange;
    transform: scale(1.05);
}

.boton-cerrar-sesion{
  background-color: #7094b2;
  border-radius: 20px;
  border: none;
}

.boton-cerrar-sesion:hover{
  background-color: #ffa500;
  border-color: #ffa500;
  transform: scale(1.05);
}

.boton-perfil{
  background-color: #7094b2;
  border-radius: 20px;
  border: none;
}

.boton-perfil:hover{
  background-color: #ffa500;
  border-color: #ffa500;
  transform: scale(1.05);
}

ul{
  animation: slideIn 1s ease; /* Aplica la animación de entrada */
}

.nav-link{
  font-size: 17px;
  /* font-family: 'San Francisco' */
  /* font-family: 'Segoe Script'; */
}

.nav-link:hover {
  background-color: #537692;
}

.navbar-brand {
  animation: slideIn 1s ease; /* Aplica la animación de entrada */
  font-weight: bold;
  display: flex;
  align-items: center;
}

.navbar{
  background:  linear-gradient(to bottom, #163e5c, #163241); 
  margin: 0 -10px;
}

/* Estilos para el botón de alternancia de Dropdown */
.nav-link.dropdown-toggle {
  background-color: transparent !important;
  color: #fff !important;
}

/* Estilos para el menú desplegable */
.dropdown-menu {
  background-color: #163241;
  text-align: center;
  border: none;
  border-radius: 15px;
  border: #0b1f2c 2px solid;
  transform: translateX(-10%);
}

@media screen and (max-width: 800px) {
  .dropdown-menu {
    transform: translateX(0);
  }
}

/* Cambio de color al pasar el mouse sobre los elementos del menú desplegable */
.dropdown-item:hover {
  background-color: #537692;
}

.navbar-toggler {
  background-color: transparent;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
  background-color: #113f64;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}