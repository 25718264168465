/* Default styles */
.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

header {
    background-color: #333;
    color: white;
    padding: 1rem;
    font-size: 30px;
}

h1 {
    margin: 0;
}

.content {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 30vh; /* Ajusta esta altura para evitar desplazamientos verticales */
}

.map-container {
    width: 100%;
    height: 30%;
}

footer {
    background-color: #333;
    color: white;
    padding: 1rem;
    text-align: center;
}

/* Mobile styles */
@media (max-width: 768px) {
    header {
        font-size: 1.5rem;
        padding: 0.5rem;
    }

    .content {
        flex-direction: column;
        align-items: stretch;
    }

    .map-container {
        height: 30vh; /* Ajusta este valor para el tamaño del mapa en móviles */
    }

    footer {
        font-size: 0.8rem;
        padding: 0.5rem;
    }

    .navbar-brand {
        font-size: 1.5rem;
    }

    .navbar-toggler {
        margin-right: 0;
    }

    .navbar-nav {
        margin-top: 1rem;
    }

    .navbar-nav li {
        margin-bottom: 0.5rem;
    }
}

