.register-form {
  padding: 2rem;
  max-width: 400px;
  margin: auto;
  background-color: #0b1f2c;
  border-radius: 5px;
  box-shadow: 0px 4px 6px #151a2d80;
  animation: slideIn 1s ease;
}

@keyframes slideIn {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.register-form__icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
}

.register-form__icon {
  font-size: 2rem;
  color: #f8f8f8;
}

.register-form__group {
  margin-bottom: 1.5rem;
}

.register-form__label {
  display: block;
  margin-bottom: 0.5rem;
  color: #d1d1d1;
}

.register-form__input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #777;
  border-radius: 4px;
  font-size: 1rem;
  color: #f8f8f8;
  background-color: #333;
}

.register-form__input:focus {
  border-color: darkolivegreen;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(85, 107, 47, 0.25);
}

.register-form__button {
  background-color: darkolivegreen;
  color: #fff;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease, transform 0.3s ease;
}

.register-form__button:hover {
  background-color: orange;
}

.register-form__button:active {
  background-color: #c76b00;
}

.register-form__button:focus {
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(85, 107, 47, 0.5);
}
.message.success {
  color: green;
  text-align: center;
}

.message.error {
  color: red;
  text-align: center;
}
