/* General styles */
.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    color: #fff;
}

.loading, .error, .no-data {
    text-align: center;
    font-size: 1.5rem;
    margin-top: 50px;
}

/* Details header */
.details-title {
    font-size: 2rem;
    text-transform: uppercase;
    text-decoration: underline;
    margin-bottom: 30px;
    text-align: center;
}

/* Image gallery */
.img-card {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
}

.img-wrapper {
    width: 500px; /* Tamaño fijo para todas las imágenes */
    height: 400px; /* Tamaño fijo para todas las imágenes */
    overflow: hidden;
}

.img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.img:hover {
    transform: scale(1.5); /* Hacer zoom al pasar el ratón */
}

/* Social links */
.social-media {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.social-icon {
    display: flex;
    align-items: center;
    margin: 10px 0;
    font-size: 1.5rem; /* Tamaño de fuente más pequeño para los enlaces */
    color: #e5e6e7;
    width: 500px; /* Ancho fijo para alinear con la imagen */
}

.social-icon .icon {
    margin-right: 10px;
    font-size: 1.5rem; /* Asegurar que los íconos sean más grandes */
}

.social-icon a {
    text-decoration: none;
    color: #e5e6e7;
    transition: color 0.3s;
    display: inline-block;
    max-width: 80%; /* Limitar el ancho del enlace */
    white-space: nowrap; /* Evitar que el texto se divida en varias líneas */
    overflow: hidden; /* Ocultar el texto que se desborda */
    text-overflow: ellipsis; /* Añadir puntos suspensivos al texto desbordante */
}

.social-icon a:hover {
    color: #ffa500;
    /* Cambia el color al pasar el cursor */
}

/* Description */
.card-detalle {
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    margin-top: 20px;
}

.card-cuerpo {
    font-size: 1.2rem;
    text-align: justify;
}

/* Map container */
.mapa {
    margin-top: 20px;
}

.mapa iframe {
    width: 100%;
    height: 500px; /* Aumentar el tamaño del mapa */
    border: none;
    border-radius: 10px;
}

/* Comments section */
.comments-container {
    margin-top: 20px;
}

.comment-submit {
    margin-top: 20px;
}

/* Overlay for expanded image */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.overlay img {
    max-width: 90%;
    max-height: 90%;
    border-radius: 10px;
}

/* Responsive styles */
@media (max-width: 800px) {
    .details-title {
        font-size: 1.5rem;
    }

    .img-wrapper {
        width: 100%;
        height: 200px;
    }

    .card-cuerpo {
        font-size: 1rem;
    }

    .mapa iframe {
        height: 300px;
    }

    .social-media, .social-icon {
        width: 100%;
        align-items: flex-start; /* Alinear al principio en dispositivos móviles */
    }
}
