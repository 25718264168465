form {
    width: 100%;
    max-width: 450px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #e1e1e1;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #0b1f2c;
    color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* Cada grupo de campos del formulario */
.div-admin {
    margin-bottom: 20px;
}

/* Etiquetas de los campos del formulario */
.label {
    display: block;
    margin-bottom: 10px;
    font-weight: 600;
    color: #fff;
    font-size: 1rem;
}

/* Campos del formulario */
input[type="text"],
input[type="email"],
input[type="password"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    font-size: 1rem;
    background-color: #183a49;
    color: #fff;
    transition: border-color 0.3s, box-shadow 0.3s;
}

/* Efecto de enfoque para los campos del formulario */
input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus {
    border-color: #007BFF;
    outline: none;
    box-shadow: 0 0 0 2px rgba(0,123,255,0.25);
}

/* Botón de enviar */
button {
    width: 100%;
    padding: 12px;
    background-color: darkolivegreen;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    margin-top: 20px;
    font-weight: 500;
}

/* Efecto de hover para el botón de enviar */
button:hover {
    background-color: orange;
    transform: translateY(-2px);
}

.tab {
    text-align: center;
}

li {
    background-color: darkolivegreen;
    color: white;
    transition: background-color 0.3s;
    border-radius: 3px;
    margin: 5px;
    display: inline-block;
    padding: 10px 20px;
    cursor: pointer;
}

li:hover {
    background-color: orange;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

li.active {
    background-color: #007bff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Estilos para mejorar la visibilidad de los tabs activos */
.react-tabs__tab--selected {
    background-color: #007bff;
    color: white;
    border-radius: 3px;
}

.react-tabs__tab-list {
    border-bottom: none;
    padding: 0;
    margin: 0;
}

.react-tabs__tab-panel {
    padding: 20px;
    border: none;
}

select{
    margin-Left: '5px';
    padding: '5px';
    border-radius: '5px';
    border: '1px solid #ccc';
    background-color: darkolivegreen;
    font-Size: '14px';
    width: '200px';
}

