/* Estilos del Formulario y Tarjetas de Servicio */
.service-form,
.service-card {
    max-width: 450px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #e1e1e1;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #0b1f2c;
    color: white;
}

/* Estilos para los Labels y Grupos de Input */
.service-edit__group,
.input-group,
.service-edit__label,
label {
    margin-bottom: 10px;
    font-weight: 600;
}

/* Estilos para Inputs y Textareas */
input[type="text"],
input[type="email"],
textarea {
    padding: 10px;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    background-color: #183a49;
    transition: border-color 0.3s, box-shadow 0.3s;
}

input[type="text"]:focus,
input[type="email"]:focus,
textarea:focus {
    border-color: #007BFF;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

/* Estilos para Botones */
button {
    padding: 12px;
    background-color: darkolivegreen;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    margin-top: 20px;
    font-weight: 500;
    width: 100%;
}

button:hover {
    background-color: orange;
    transform: translateY(-2px);
}

/* Estilos para la Lista de Servicios */
.service-list__item {
    background-color: darkolivegreen;
    transition: background-color 0.3s;
    border-radius: 3px;
    margin: 5px;
    padding: 10px 20px;
}

.service-list__item:hover {
    background-color: darkolivegreen;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Estilos para las Imágenes del Servicio */
.service-images {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.service-card {
    margin-bottom: 10px;
}

.service-card img {
    width: 100%;
    height: 160px;
    /* Altura fija para todas las imágenes */
    object-fit: cover;
    border-radius: 4px;
    margin-bottom: 5px;
}

/* Estilos para el Checkbox dentro de la Card */
.service-card .card-body input[type="checkbox"] {
    width: 20px;
    height: 20px;
    margin-top: 10px;
}

/* Estilos para el Botón de Carga y Borrado Masivo */
.service-images__button-delete,
.service-upload__button-upload {
    padding: 8px 10px;
    margin-top: 10px;
    align-self: center;
    display: block;
    margin: 10px auto;
}

/* Estilos para la Sección de Carga */
.service-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 20px;
    padding: 20px;
}

select {
    margin-Left: '5px';
    padding: '5px';
    border-radius: '5px';
    border: '1px solid #ccc';
    background-color: darkolivegreen;
    font-Size: '14px';
    width: '200px';
}