.carousel, .carruselN, .carruselT {
  animation: slideIn 1s ease; /* Aplica la animación de entrada */
  width: 100%; /* Ajusta el ancho del carrusel al 100% de la página */
}

.carousel-item img, .ItemN, .itemT {
  width: 100%; /* Ajusta el ancho de las imágenes al 100% del carrusel */
  height: 350px;
  animation: slide 1s ease-in-out; 
}

.carousel-item img {
  height: 450px; /* Ajusta la altura de las imágenes de manera proporcional */
}

.carousel-inner {
  box-shadow: 10px 10px 30px rgb(12, 23, 59); /* Agrega sombreado al contorno interior del carrusel */
}


