Footer {
    background: linear-gradient(to bottom, #173444, #224e66, #537692);
    transition: background-color 0.4s ease;
}

p{
    color: white;
    font-size: 20px;
    margin: 20px;
    margin-top: 26px;
}

@media screen and (max-width: 800px) {
    .image-container {
        margin-left: -20px;
    }

    .img1 {
        max-width: 125px;
    }

    .img2 {
        max-width: 60px;
    }

    .img3 {
        max-width: 60px;
        margin: 30px;
    }

    p {
        font-size: 15px;
        margin: 10px;
        margin-top: 15px;
    }
}