iframe {
  border-radius: 10px;
}

.inicio-home {
  background-color: #173444;
}

.card {
  background-color: #537692;
  margin: 10px;
  padding: 10px;
}

h1 {
  text-align: center;
  font-size: 60px;
  font-family: Arial, Helvetica, sans-serif;
  animation: slideIn 1s ease;
  /* Aplica la animación de entrada */

}

h2 {
  font-size: 40px;
  font-family: Arial, Helvetica, sans-serif;
  animation: slideIn 1s ease;
  /* Aplica la animación de entrada */

}

h3 {
  font-size: 24px;
  font-family: Arial, Helvetica, sans-serif;
}

h4 {
  font-size: 18px;
  color: #ffffff;
  font-family: Arial, Helvetica, sans-serif;
}

.boton-informacion-atractivos-home {
  transition: background-color 0.3s ease, transform 0.3s ease;
  background-color: #145c96;
  border: none;
  font-family: Arial, Helvetica, sans-serif;
  padding: 8px 16px;
  width: fit-content;
  border-radius: 20px;
}

.boton-informacion-atractivos-home:hover {
  background-color: #ffa500;
  border-color: #ffa500;
  transform: scale(1.002);
}

.boton-informacion-esenciales-home {
  transition: background-color 0.3s ease, transform 0.3s ease;
  background-color: #145c96;
  border: none;
  font-family: Arial, Helvetica, sans-serif;
  padding: 8px 16px;
  width: fit-content;
  border-radius: 20px;
}

.boton-informacion-esenciales-home:hover {
  background-color: #ffa500;
  border-color: #ffa500;
  transform: scale(1.002);
}

.container-atractivos-home {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  color: #fff;
}

/* Definición de la clase para el título */
.novedades-title {
  font-family: "Arial";
  color: #fff;
  margin: 20px;
  animation: slideInLeft 1s ease;
}

.card-title-atractivos-home {
  text-align: center;
}

.btn-atractivos-home {
  display: flex;
  justify-content: center;
  margin: 10px;
}

.card-atractivos-home {
  padding: 0.3rem 1rem;
  font-size: 1rem;
  border-radius: 10px;
  border-top: 2px solid #ffffffa8;
  border-bottom: 2px solid #ffffffa8;
}

.contactos-home {
  background-color: #2a638f;
}

.card-body-atractivos-home {
  margin: 10px;
}

.social-button-ig-home {
  display: inline-block;
  transition: transform 0.3s, color 0.3s;
  width: 50px;
  margin-left: 20px;
}

.social-button-ig-home:hover {
  transform: scale(1.2);
}

.social-button-face-home {
  display: inline-block;
  transition: transform 0.3s, color 0.3s;
  width: 45px;
}

.social-button-face-home:hover {
  transform: scale(1.2);
}

.botones-esenciales-home {
  text-align: center;
  margin-top: 30px;
}

.container-esenciales-home {
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px 10px;
  color: #fff;
}

/* Añadir estilos específicos para pantallas con ancho menor a 800px */
@media screen and (max-width: 800px) {
  #Contacto_Inicio-Home {
    text-align: center;
  }

  h1 {
    font-size: 28px;
    margin-top: 50px;
  }

  h2 {
    font-size: 16px;
    text-align: center;
  }

  .social-buttons-home {
    margin-left: 10px;
    margin-top: 10px;
  }

  .social-icon-home {
    padding: 10px;
    display: inline-block;
    margin: 0 10px;
    font-size: 26px;
    color: #e5e6e7;
    transition: transform 0.3s, color 0.3s;
  }

  .social-icon-home:hover {
    transform: scale(1.2);
    /* Escala el ícono al pasar el cursor */
    color: #ffa500;
    /* Cambia el color al pasar el cursor */
  }

  .social-button-ig-home {
    width: 50px;
    margin-left: 1px;
  }

  .social-button-face-home {
    width: 45px;
  }
}

.social-icon-home {
  padding: 10px;
  display: inline-block;
  margin: 0 10px;
  font-size: 26px;
  color: #e5e6e7;
  transition: transform 0.3s, color 0.3s;
}

.social-icon-home:hover {
  transform: scale(1.2);
  /* Escala el ícono al pasar el cursor */
  color: #ffa500;
  /* Cambia el color al pasar el cursor */
}


/* EJEMPLO VISTA MOVIL CSS

/* Media Query para estilos móviles 
@media screen and (max-width: 768px) {
  header {
      font-size: 1.5em;
  }

  .contenido {
      padding: 0.5em;
  }

  img {
      max-width: 100%;
      height: auto;
  }

  footer {
      font-size: 0.8em;
  }
}
*/

a {
  text-decoration: none;
  padding: 10px;
  color: #ffffff;
  cursor: default;
  background-color: transparent;
}

a:hover {
  color: orange;
}


