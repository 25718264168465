.title-turismo {
    color: #fff;
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
    margin-top: 20px;
}

.img-turismo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.img-turismo:hover {
  transform: scale(1.05);
    cursor: pointer;
}

.boton-info-turismo {
    transition: background-color 0.3s ease, transform 0.3s ease;
    background-color: #145c96;
    border: none;
    font-family: Arial, Helvetica, sans-serif;
    width: fit-content;
    align-self: center;
    border-radius: 20px;
    margin-top: 10px;
}

.boton-info-turismo:hover {
    background-color: #ffa500;
    border-color: #ffa500;
    transform: scale(1.02);
}

/* Agrega estilos específicos para el Modal */
.modal-content {
    background-color: #537692;
    color: #fff;
  }
  
  .modal-header {
    border-bottom: none;
  }
  
  .modal-title {
    font-size: 1.5rem;
  }
  
  /* Estilos para el cuerpo del Modal */
  .modal-body {
    background-color: #537692;
    padding: 20px;
  }
  
  .container {
    margin-bottom: 20px;
  }
  
  .card-turismo {
    padding: 10px;
    border-radius: 8px;
    background-color: #3378a6a0;
    border-radius: px;
    margin: 10px;
    width: 100%;
  }
  
  /* Estilos para el pie del Modal */
  .modal-footer {
    background-color: #537692;
    border-top: none;
  }
  
  /* Estilos para el botón de Cerrar */
  .modal-footer button {
    background-color: #3378a6a0;
    border-color: #1d4167;
    border-radius: 5px;
    padding: 8px 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .modal-footer button:hover {
    background-color: #864444;
  }
  